//@ts-check
import React from 'react';


const LogoTiles = ({
  topDivider,
  bottomDivider,
  ...props
}) => {


  return (
    <section
      {...props}
    >
      <div className="container">
        <div className={`section-inner ${bottomDivider && 'has-bottom-divider'}`} style={{paddingTop: "0"}}>

          <div style={{color: "#969696", fontSize:"14px"}}>
            CLIENTES
          </div>
          <div style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
            <div>
              <div>
                <img
                  className="spacer-right-12-mobile"
                  src={require('../assets/images/premier-logo.svg')}
                  alt="Grupo Premier"
                  width={150} 
                  />
              </div>
            </div>

            <div data-reveal-delay="200">
              <div>
                <img
                  className="spacer-right-12-mobile"
                  src={require('../assets/images/itesm-logo.svg')}
                  alt="ITESM"
                  width={180} 
                  />
              </div>
            </div>

            <div className="reveal-from-bottom" data-reveal-delay="400">
              <div>
                <img
                  className="spacer-right-12-mobile"
                  src={require('../assets/images/dportenis-logo.svg')}
                  alt="Dportenis"
                  width={180} 
                  />
              </div>
            </div>

            {/* flex-basis for width */}

            <div className="reveal-from-bottom hide-medium" data-reveal-delay="400">
              <div>
                <img
                  src={require('../assets/images/aersa-logo.svg')}
                  alt="Aersa"
                  height={70} />
              </div>
            </div>

            <div className="reveal-from-bottom hide-medium" data-reveal-delay="400">
              <div>
                <img
                  src={require('../assets/images/fyttsa-logo.svg')}
                  alt="Fyttsa"
                  width={140}
                   />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default LogoTiles;