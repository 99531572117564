//@ts-check
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Input from '../components/elements/Input'
import Button from '../components/elements/Button'

// icons
import favicon from '../assets/images/favicon.ico'

import { tagManager, bodyTagManager } from '../scripts'
import LogoTiles from '../components/LogoTiles'

import styles from "./demo.module.css"


const analyticEvent = (category, action) => {
  const gtag = window.gtag || null;

  if (!gtag) return;

  gtag('event', 'conversion', { 'send_to': 'AW-970266593/g5xKCOLAo7cZEOGv1M4D' });

}


const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Demo extends React.Component {

  constructor(props) {
    super(props);
    this.state = { name: "", email: "", comentarios: "", empresa: "" };
  }

  handleSubmit = e => {

    analyticEvent('event', 'conversion');

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "demo", ...this.state })
    })
      .then(() =>
        this.setState({ success: true })
      )
      .catch(error => alert(error));

    e.preventDefault();
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {

    const { name, email, comentarios, empresa } = this.state;

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={"Agenda un Demo | ScreenFox"}>

            <html lang="es" />
            <link rel="alternate" href={`https://getscreenfox.com${this.props.location.pathname}`} hrefLang="es" />
            <link rel="icon" href={favicon} type="image/x-icon" />

            <meta name="description" content={`Agenda un demo personalizado para tu empresa y conoce el poder del digital sigange.`} />
            <meta property="og:title" content={`Agenda un demo - ScreenFox`} />
            <meta property="og:description" content={`Agenda un demo personalizado para tu empresa y conoce el poder del digital sigange.`} />

            {/* TODO: Add image */}
            {/* <meta property="og:image" content={``} /> */}

            <script>{tagManager}</script>
          </Helmet>

          <div
            dangerouslySetInnerHTML={{
              __html: bodyTagManager,
            }}></div>


          <section>
            <div className="container">
              <div style={{ padding: "100px 10px 30px" }}>

                <div className={styles.flexDiv}>

                  <div className={styles.flexCol}>
                    <h1>Descubre lo que <span className="">ScreenFox</span> puede hacer por ti</h1>
                    <p>Cuéntanos un poco de ti, y te contactaremos para hacerte un demo personalizado de cómo funciona nuestro producto y resolveremos cualquier duda que tengas.</p>
                  </div>

                  <div className={styles.flexCol} style={{ marginTop: "20px" }}>

                    {this.state.success &&
                      <h3>Vas a recibir un correo de nosotros muy pronto.</h3>
                    }

                    {!this.state.success &&
                      <>
                        <h3>Llena los siguientes datos:</h3>
                        <form name="demo" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
                          <input type="hidden" name="demo" value="demo" />
                          <fieldset>
                            <div className="mb-16">
                              <Input
                                type="text"
                                name="name"
                                placeholder="Nombre"
                                value={name}
                                onChange={this.handleChange}
                                required />
                            </div>
                            <div className="mb-16">
                              <Input
                                type="email"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                                placeholder="Email"
                                required />
                            </div>
                            <div className="mb-16">
                              <Input
                                type="text"
                                name="empresa"
                                value={empresa}
                                onChange={this.handleChange}
                                placeholder="Empresa"
                                required />
                            </div>
                            <div className="mb-16">
                              <Input
                                type="textarea"
                                name="comentarios"
                                onChange={this.handleChange}
                                value={comentarios}
                                placeholder="Comentarios" />
                            </div>
                            <div className="mt-24">
                              <div className="button-group">

                                {this.state.success
                                  ? <p>Gracias! Vas a recibir un correo de nosotros muy pronto.</p>
                                  : <Button type="submit" color="primary">Agendar demo</Button>
                                }

                              </div>
                            </div>
                          </fieldset>
                        </form>
                      </>
                    }

                  </div>
                </div>

                <br />
                <br />
                <LogoTiles />
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Demo
